@import '~darrius/src/styles/mixins/media';
@import '~darrius/src/styles/mixins/scale';

.auth__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;

  h1 {
    text-align: center;
    line-height: var(--font-line-height-compact);
    color: var(--color-neutral-dark-600);
    font-size: var(--size-font-2xl);
    font-weight: var(--font-weight-semibold);
  }

  p {
    margin-top: var(--size-spacing-stack-100);

    text-align: center;
    line-height: var(--font-line-height-wide);
    color: var(--color-neutral-dark-400);
    font-size: var(--size-font-m);
    font-weight: var(--font-weight-regular);
  }
}

.pin_loading {
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    margin-top: var(--size-spacing-inline-200);

    border-top-color: var(--color-action-500);
    border-right-color: var(--color-action-500);
    border-bottom-color: var(--color-action-500);

    width: 2rem;
    height: 2rem;
  }
}

.resend_button {
  margin-top: var(--size-spacing-stack-100);

  &:disabled {
    cursor: not-allowed;
  }
}
