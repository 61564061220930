.chat {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  gap: 1rem;

  margin-top: var(--size-spacing-stack-700);
  margin-bottom: var(--size-spacing-stack-1200);

  border-radius: var(--size-border-radius-m);

  background-color: var(--color-action-100);

  padding: var(--size-spacing-stack-700) var(--size-spacing-inline-500);

  width: 100%;
  height: 100px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;    
  }
}

.chat__botton__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
}

.chat__botton {
  width: 200px;
  height: 60px;
  @media (max-width: 768px) {
    width: 300px;
  }  
}

.chat__text {
  flex: 1;
  flex-wrap: wrap;

  span {
    line-height: var(--font-line-height-strict);
    font-size: var(--size-font-xl);
    font-weight: var(--font-weight-semibold);
  }

  @media (max-width: 768px) {
    span {
      font-size: var(--size-font-l);
      font-weight: var(--font-weight-semibold);
    }
  }
}
