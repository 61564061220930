.modal_interaction {
  :global {
    .picture img {
      height: revert-layer;
    }

    .paragraph {
      margin-bottom: 0;
    }
  }

  &__paragraph_desktop {
    margin-bottom: var(--size-spacing-inline-100);
    max-width: 365px;
  }

  &__paragraph_mobile {
    :global {
      .paragraph {
        text-align: left;
      }
    }
  }
}
