.orders {
  margin-bottom: var(--size-spacing-stack-500);
  width: 100%;

  &__date {
    line-height: var(--font-line-height-normal);
    color: var(--color-neutral-dark-200);
    font-size: var(--size-font-base);
    font-weight: var(--font-weight-regular);
  }

  &__card {
    margin-top: var(--size-spacing-stack-300);
    height: auto;
  }

  &__card_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    svg {
      color: var(--color-neutral-dark-200);
    }
  }

  &__card_header__line {
    opacity: 0.2;
    margin-top: var(--size-spacing-stack-300);
    width: 100%;
    color: var(--color-neutral-dark-100);
  }

  &__card_footer__line {
    opacity: 0.2;
    margin-bottom: var(--size-spacing-stack-300);
    width: 100%;
    color: var(--color-neutral-dark-100);
  }

  &__card_body {
    padding: 0 var(--size-spacing-inline-900) var(--size-spacing-stack-400)
      var(--size-spacing-inline-900);
  }

  &__status {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    gap: var(--size-spacing-stack-100);
  }

  &__profiles {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    margin-top: var(--size-spacing-stack-500);

    li {
      list-style: none;
    }

    li + li {
      margin-left: var(--size-spacing-inline-100);
    }
  }

  :global {
    .card-footer {
      padding: 0 var(--size-spacing-inline-900) var(--size-spacing-stack-500);
      text-align: center;
    }
  }
}
