@import '~darrius/src/styles/mixins/media';

.category-page {
  > *:nth-child(even) {
    background-color: var(--color-neutral-light-200);
  }

  :global {
    .best-pros,
    .cta-pro,
    .budgets,
    .pro-list,
    .average-prices,
    .similar-requests,
    .faq {
      @include media(normal) {
        padding: var(--size-spacing-stack-1400) 0;
      }

      padding: var(--size-spacing-stack-900) 0;
    }
  }

  .main-section {
    :global {
      .breadcrumb {
        @include media(normal) {
          margin-bottom: var(--size-spacing-stack-1000);
        }

        margin-bottom: var(--size-spacing-stack-700);
      }
    }
  }

  .form-and-ratings {
    @include media(normal) {
      background: linear-gradient(
        90deg,
        var(--color-neutral-light-100) 0%,
        var(--color-neutral-light-100) 50%,
        var(--color-neutral-light-200) 51%,
        var(--color-neutral-light-200) 100%
      );
    }

    padding: 0;

    :global {
      .container {
        padding: 0;
      }
    }

    .form-area,
    .ratings {
      @include media(normal) {
        padding: var(--size-spacing-stack-1200) var(--size-spacing-inline-700);
      }

      padding: var(--size-spacing-stack-900) var(--size-spacing-inline-300);
    }

    .form-area {
      @include media(normal) {
        padding: var(--size-spacing-stack-1200) var(--size-spacing-inline-700);
      }

      background-color: var(--color-brand-500);
      padding: var(--size-spacing-stack-300) var(--size-spacing-inline-300);
    }

    &__text p {
      margin-bottom: var(--size-spacing-stack-200);
      line-height: var(--font-line-height-narrow);
      color: var(--shade-100);
      font-size: var(--size-font-l);
      font-weight: var(--font-weight-semibold);
    }
  }

  @media (max-width: 996px) {
    :global {
      .row.slider-sm {
        flex-wrap: nowrap;
        justify-content: flex-start;
        overflow-x: scroll;

        // Quando houver mais de 1, seleciona todos os filhos diretos.
        > *:not(:only-child) {
          margin-right: var(--size-spacing-inline-300);
          max-width: 85%;
        }
      }
    }
  }
}
