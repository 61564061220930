@import '~darrius/src/styles/mixins/media';

// .price-analysis {
//   :global {
//     .link-list-item {
//       // margin-top: 12px;
//       list-style: none;
//     }
//   }
// }

.price__hero {
  :global {
    @media (min-width: 992px) {
      .Certify_certify__d4uOz,
      .Badges_badges-class__JJwPH.undefined.badges__default {
        margin: var(--size-spacing-stack-500) 0 !important;
      }
    }
  }
}

.price-analysis__title-medium p {
  margin-bottom: var(--size-spacing-stack-200);
  line-height: var(--font-line-height-narrow);
  color: var(--shade-100);
  font-size: var(--size-font-3xl);
  font-weight: var(--font-weight-semibold);
}

.price-analysis {
  :global {
    .link-list-item {
      margin-top: 12px;
      list-style: disc !important;

      &--child {
        margin-left: 36px;
      }

      &__no-list-style {
        margin-top: 12px;
        list-style: none !important;
      }
    }
  }
}

.list-component__price {
  @include media(normal) {
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
  }

  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: row;
  list-style-type: var(--none, none);
  text-decoration: var(--none, none);
  gap: 8px;
}
