@import '~darrius/src/styles/mixins/media';

.main-image {
  display: flex; // Remove padding embaixo da imagem do topo da página

  @media (max-width: 768px) {
    img {
      width: 100%;
      height: auto;
    }
  }
}

.link {
  display: flex;
  align-items: center;
  border-top: var(--size-border-width-thin) solid var(--color-neutral-light-400);
  padding: var(--size-spacing-stack-200) 0;
  min-height: var(--size-spacing-stack-fluid-1200);

  h2 {
    color: var(--color-action-500);
    font-size: var(--size-font-l);
    font-weight: var(--font-weight-regular);
  }
}
