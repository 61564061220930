.container {
  background-color: var(--color-neutral-light-100);
  height: 100%;
}

.content {
  margin-top: var(--size-spacing-stack-500);
}

.items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--size-spacing-stack-100);

  &::after {
    display: block;
    margin: var(--size-spacing-stack-100) 0;
    background-color: var(--color-neutral-light-300);
    width: 100%;
    height: 1px;
    content: '';
  }

  &:first-child {
    margin-top: var(--size-spacing-stack-700);
  }

  h2 {
    color: var(--color-neutral-dark-400);
  }

  p {
    line-height: var(--font-line-height-normal);
    color: var(--color-neutral-dark-200);
    font-size: var(--size-font-m);
    font-weight: var(--font-weight-regular);
  }
}
