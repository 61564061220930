.item {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.card {
  display: grid;
  grid-template-columns: [picture] 60px [body] auto [sidebar] auto;
  column-gap: var(--size-spacing-stack-100);
  padding: var(--size-spacing-stack-100);

  &--selected {
    background-color: var(--color-action-100);
  }
}

.card__title {
  line-height: var(--font-line-height-compact);
  color: var(--color-neutral-dark-300);
  font-size: var(--size-font-base);
  font-weight: var(--font-weight-semibold);
}

.card__subtitle {
  line-height: var(--font-line-height-strict);
  color: var(--color-neutral-light-600);
  font-size: var(--size-font-xs);
  font-weight: var(--font-weight-regular);
}

.card__body {
  line-height: var(--font-line-height-normal);
  color: var(--color-neutral-dark-100);
  font-size: var(--size-font-s);
  font-weight: var(--font-weight-regular);
}

.card__sidebar {
  margin-left: auto;
  text-align: right;
}

.card__badge {
  display: inline-flex;
  border-radius: 50%;
  background-color: var(--color-negative-500);
  padding: calc(var(--size-spacing-inline-100) / 4)
    var(--size-spacing-stack-100);
  line-height: var(--font-line-height-compact);
  color: var(--color-neutral-light-100);
  font-size: var(--size-font-xs);
}
