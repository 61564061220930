.text {
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 30px 0;
  }

  p,
  li {
    margin: 20px 0;
  }

  li {
    padding-left: 10px;
  }

  ul {
    list-style-type: disc;
  }

  ul,
  ol {
    padding-left: 30px;
  }

  ol {
    list-style-type: lower-roman;
  }
}
