@import '~darrius/src/styles/mixins/media';
@import '~darrius/src/styles/mixins/scale';

.phoneVerification__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;

  &_button {
    margin-top: var(--size-spacing-inline-100);

    text-transform: uppercase;
  }
}

.phoneVerification__footer {
  div + div {
    margin-top: var(--size-spacing-stack-500);
  }

  &_phone {
    margin-bottom: var(--size-spacing-stack-300);

    border-bottom: 1px solid rgba(0, 0, 0, 0.35);

    padding: var(--size-spacing-inset-square-100);

    width: 100%;
  }

  &_whatsapp {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    margin-bottom: var(--size-spacing-stack-500);

    > div {
      margin: 0 0 0 var(--size-spacing-inline-100);
    }
  }
}
