.arrow_back {
  all: unset;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  margin-bottom: var(--size-spacing-stack-300);
  cursor: pointer;

  span {
    line-height: var(--font-line-height-narrow);
    color: var(--color-neutral-dark-300);
    font-size: var(--size-font-base);
    font-weight: var(--font-weight-semibold);
  }
}
