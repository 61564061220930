@import '~darrius/src/styles/mixins/media';

.container {
  @include media(normal) {
    flex-direction: row;
    align-items: flex-start;
    padding-bottom: var(--size-spacing-stack-1000);
  }

  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  margin-bottom: var(--size-spacing-stack-1000);
  color: var(--color-neutral-dark-200);

  &::after {
    position: absolute;
    right: 10%;
    bottom: 0;
    left: 10%;
    border-bottom: var(--size-border-width-thin) solid
      var(--color-neutral-light-500);
    content: '';
  }

  > * {
    flex: 1 1 50%;
  }
}

.section {
  @include media(normal) {
    padding: 0 var(--size-spacing-inset-square-1200);
    padding-bottom: 0;
  }
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  padding: 0 var(--size-spacing-inline-500) var(--size-spacing-stack-200);

  > * + * {
    margin-top: var(--size-spacing-stack-200);
  }
}

.image {
  @include media(normal) {
    margin-right: auto;
    margin-left: auto;
    width: 400px;
  }
  margin-top: calc(var(--size-spacing-stack-500) * -1);
}

.status {
  @include media(normal) {
    padding-bottom: var(--size-spacing-stack-300);
  }
  padding: 0 10%;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 45px;
  text-align: start;
  line-height: var(--font-line-height-strict);
  font-size: var(--size-font-base);
  font-weight: var(--font-weight-regular);
  gap: 8px;

  span {
    width: 100%;
  }
}

.title h1 {
  @include media(normal) {
    font-size: var(--size-font-6xl);
  }
  line-height: var(--font-line-height-strict);
  color: var(--color-action-500);
  font-size: var(--size-font-2xl);
  font-weight: var(--font-weight-bold);
}

.subtitle p {
  margin: 0;
  color: var(--color-neutral-dark-200);
  font-size: var(--size-font-l);
  font-weight: var(--font-weight-regular);
}

.hows-your-request h3 {
  line-height: var(--font-line-height-strict);
  color: var(--color-neutral-dark-200);
  font-size: var(--size-font-l);
  font-weight: var(--font-weight-semibold);
}

.warning p {
  @include media(normal) {
    margin: var(--size-spacing-stack-300) auto 0 auto;
    max-width: 70%;
  }
  margin-top: var(--size-spacing-stack-300);
  margin-bottom: var(--size-spacing-stack-100);
  border-radius: var(--size-border-radius-s);
  background-color: var(--color-brand-300);
  padding: 12px 8px;
  text-align: center;
  line-height: var(--font-line-height-compact);
  color: var(--color-neutral-dark-300);
  font-size: var(--size-font-base);
  font-weight: var(--font-weight-semibold);
}

.open-camera {
  line-height: var(--font-line-height-narrow);
  color: var(--color-neutral-dark-200);
  font-size: var(--size-font-l);
  font-weight: var(--font-weight-regular);
}

.download-app p {
  margin-bottom: var(--size-spacing-stack-300);
  line-height: var(--font-line-height-compact);
  color: var(--color-action-500);
  font-size: var(--size-font-l);
  font-weight: var(--font-weight-semibold);
}

.might-interest h2 {
  margin-bottom: var(--size-spacing-stack-500);
  line-height: var(--font-line-height-narrow);
  color: var(--color-neutral-dark-200);
  font-size: var(--size-font-2xl);
  font-weight: var(--font-weight-semibold);
}

.most-requested-services {
  margin-bottom: var(--size-spacing-stack-1000);
  padding: 0 var(--size-spacing-stack-100) 0 var(--size-spacing-stack-500);

  .service {
    position: relative;
    border-radius: var(--size-border-radius-l);
    color: var(--color-neutral-light-100);

    img {
      border-radius: var(--size-border-radius-l);
    }

    &__details {
      position: absolute;
      bottom: 0;
      border-radius: var(--size-border-radius-l);
      background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(1, 1, 1, 0));
      padding: var(--size-spacing-inset-square-300);
      width: 100%;
      text-align: start;
    }

    &__name {
      display: block;
      margin-bottom: var(--size-spacing-stack-100);
      line-height: var(--font-line-height-strict);
      font-size: var(--size-font-l);
      font-weight: var(--font-weight-semibold);
    }

    &__hire {
      display: block;
      line-height: var(--font-line-height-strict);
      font-size: var(--size-font-m);
      font-weight: var(--font-weight-regular);
    }
  }

  .service + .service {
    @include media(normal) {
      margin-left: var(--size-spacing-inline-300);
    }
    margin-left: var(--size-spacing-inline-100);
  }
}

.qrcode-area {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: var(--size-spacing-inline-1300);
  margin-bottom: var(--size-spacing-stack-800);
  padding: 0 5%;
}

.post-request-hero__button {
  padding-bottom: var(--size-spacing-inline-300);
  width: 100%;
}

.post-request-hero__comum-button {
  padding: 0 var(--size-spacing-inline-900);
  width: 100%;
}

.post-request-hero__button-desktop {
  margin: 0 auto;
  padding-bottom: var(--size-spacing-stack-300);
  width: 100%;

  p {
    line-height: var(--font-line-height-compact);
    color: var(--color-action-500);
    font-size: var(--size-font-xl);
    font-weight: var(--font-weight-semibold);
  }
}

.post-request-hero-text-mobile {
  margin: var(--size-spacing-stack-600) 0 var(--size-spacing-stack-700) 0;

  p {
    text-align: left;
    line-height: var(--font-line-height-strict);
    color: var(--color-neutral-dark-200);
    font-size: var(--size-font-base);
    font-weight: var(--font-weight-regular);
  }
}

.post-request-hero__fraud {
  margin-top: var(--size-spacing-stack-700);
  margin-bottom: var(--size-spacing-stack-300);
}

.alert-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
}
