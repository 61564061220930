@import '~darrius/src/styles/mixins/media';

.root {
  @include media(normal) {
    margin-top: 0;
  }
  display: flex;
  justify-content: space-between;
  border: var(--size-border-width-thick) dashed var(--color-negative-600);
  border-radius: var(--size-border-radius-m);
  background-color: var(--color-brand-200);
  margin: var(--size-spacing-stack-900) 0;
  cursor: pointer;
  padding: var(--size-spacing-stack-200);
  gap: var(--size-spacing-stack-fluid-100);
}

.root--row {
  align-items: center;
}

.root--column {
  flex-direction: column;
  align-items: flex-start;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--size-spacing-stack-fluid-100);
}

.link {
  border-width: 0;
  background-color: transparent;
}

.title {
  text-align: left;
  line-height: var(--font-line-height-normal);
  color: var(--color-neutral-dark-300);
  font-size: var(--size-font-base);
  font-weight: var(--font-weight-semibold);
}

.text {
  @include media(normal) {
    font-size: var(--size-font-s);
  }
  line-height: var(--font-line-height-normal);
  color: var(--color-neutral-dark-300);
  font-size: var(--size-font-s);
  font-weight: var(--font-weight-regular);
}

.alert-text-strong {
  color: var(--color-action-500);
}
