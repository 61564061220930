.shell {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.shell__header {
  flex-shrink: 0;
}

.shell__footer {
  position: fixed;
  bottom: 0;

  flex-shrink: 0;

  background-color: var(--shade-900);

  width: 100%;
  height: 104px;
}

.shell__content {
  flex-grow: 1;
  background-color: var(--shade-800);
  padding: 48px 0 104px;
  overflow: auto;
}
