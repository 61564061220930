@import '~darrius/src/styles/mixins/media';

.service {
  @include media(normal) {
    padding: var(--size-spacing-inset-square-700);
  }

  position: relative;
  margin-bottom: var(--size-spacing-stack-200);
  padding: var(--size-spacing-stack-200) 0;
}

.online {
  @include media(normal) {
    top: var(--size-spacing-stack-400);
    right: initial;
  }

  position: absolute;
  top: var(--size-spacing-stack-200);
  right: var(--size-spacing-inline-100);
}
