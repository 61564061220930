@import '~darrius/src/styles/mixins/media';

.banner-root {
  margin-bottom: 32px;

  img {
    width: 100%;
    height: 100%;
  }
}

.mobile,
.desktop {
  display: none;
  width: 100%;
}

.mobile {
  @media (max-width: 992px) {
    display: block;
  }
}

.desktop {
  @media (min-width: 993px) {
    display: block;
  }
}
