.container {
  background-color: var(--color-neutral-light-200);
  height: 100vh;

  &__content {
    height: 100%;

    h1 {
      text-align: left;
      line-height: var(--font-line-height-compact);
      color: var(--color-neutral-dark-500);
      font-size: var(--size-font-l);
      font-weight: var(--font-weight-semibold);
    }
  }

  &__action {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &_row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      gap: var(--size-spacing-stack-300);
    }
  }
}

.back__button {
  all: unset;
  cursor: pointer;
}
