@import '~darrius/src/styles/mixins/scale';
@import '~darrius/src/styles/mixins/media';
@import '~darrius/src/styles/mixins/display';

.content--as-column {
  flex-direction: column;
  gap: var(--size-spacing-inline-300);
}

.main-section {
  padding-bottom: var(--size-spacing-stack-1200);
}
