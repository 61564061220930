.container {
  background-color: var(--color-neutral-light-100);
  padding-bottom: var(--size-spacing-stack-500);

  &_content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    padding: 0 var(--size-spacing-inline-300);

    width: 100%;
    height: 100%;

    &__category {
      width: 100%;

      button {
        margin-top: var(--size-spacing-stack-100);
        padding: 0;
      }
    }

    &__category::after {
      display: block;
      margin: var(--size-spacing-stack-300) 0;
      background-color: var(--color-neutral-light-300);
      width: 100%;
      height: 1px;
      content: '';
    }
  }
}

.content {
  flex: 1;
  background-color: var(--color-neutral-light-200);
  width: 100%;
  height: 100%;
}
