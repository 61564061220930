.tags__list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  list-style-type: var(--none, none);
}

.tags__item {
  padding: 4px;
}
