.order__category {
  line-height: var(--font-line-height-normal);
  color: var(--color-neutral-dark-200);
  font-size: var(--size-font-base);
}

.order__category_root {
  line-height: var(--font-line-height-compact);
  color: var(--color-neutral-dark-600);
  font-size: var(--size-font-l);
  font-weight: var(--font-weight-semibold);
}
