.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  background-color: var(--color-neutral-light-200);
  padding: var(--size-spacing-stack-100);
  width: 100%;
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--size-spacing-stack-300) 0;
  gap: var(--size-spacing-stack-100);

  width: 100%;

  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: var(--size-spacing-stack-700);
    width: 100%;

    gap: var(--size-spacing-stack-100);
  }
}

.list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: var(--size-spacing-stack-100);
  gap: var(--size-spacing-stack-300);
  width: 100%;
  list-style: none;

  li {
    width: 100%;
  }
}
