@import '~darrius/src/styles/mixins/media';

.category-page {
  > *:nth-child(even) {
    background-color: var(--color-neutral-light-200);
  }

  :global {
    .best-pros,
    .cta-pro,
    .budgets,
    .pro-list,
    .average-prices,
    .similar-requests,
    .faq {
      @include media(normal) {
        padding: var(--size-spacing-stack-1400) 0;
      }

      padding: var(--size-spacing-stack-900) 0;
    }
  }

  .main-section {
    :global {
      .breadcrumb {
        @include media(normal) {
          margin-bottom: var(--size-spacing-stack-1000);
        }

        margin-bottom: var(--size-spacing-stack-700);
      }
    }

    &__text {
      margin-left: 2px;
      color: var(--color-neutral-dark-100);
      font-weight: 600;
    }

    // Necessário para a responsividade em desktop, fazendo o título ficar próximo dos itens abaixo
    &__title-area {
      @include media(normal) {
        margin-bottom: -12rem;
      }

      @include media(large) {
        margin-bottom: -14rem;
      }

      @media (max-width: 996px) {
        margin-bottom: var(--size-spacing-inline-400);
      }
    }

    &__hero-image {
      margin-top: var(--size-spacing-inline-500);
    }

    @media (max-width: 996px) {
      &__title {
        margin-bottom: 0;

        h1 {
          margin: 0;
          font-size: var(--size-font-2xl);
        }
      }
    }
  }

  @media (max-width: 996px) {
    :global {
      .row.slider-sm {
        flex-wrap: nowrap;
        justify-content: flex-start;
        overflow-x: scroll;

        // Quando houver mais de 1, seleciona todos os filhos diretos.
        > *:not(:only-child) {
          margin-right: var(--size-spacing-inline-300);
          max-width: 85%;
        }
      }
    }
  }
}

.main-section__image {
  @include media(normal) {
    display: flex;
    justify-content: flex-end;
  }
}

.max-width-full {
  display: block;
  max-width: 100%;
}

@media (max-width: 768px) {
  .max-width-full {
    height: auto;
  }
}

.max-width-full {
  display: block;
  max-width: 100%;
}

@media (max-width: 768px) {
  .max-width-full {
    height: auto;
  }
}

.list-title {
  h2 {
    @include media(normal) {
      font-size: var(--size-font-3xl);
    }

    font-size: var(--size-font-xl);
  }

  p {
    @include media(normal) {
      font-size: var(--size-font-l);
    }

    font-size: var(--size-font-m);
  }
}

.line {
  border: none;
  background-color: var(--color-neutral-light-300);
  height: 1px;
}

.banner {
  max-width: 700px;
}

.row-pro-card {
  :global {
    .flat-card {
      margin-top: var(--size-spacing-inline-100);
      margin-bottom: var(--size-spacing-inline-100);
    }
  }
}

@media (max-width: 768px) {
  .hide-items-mobile {
    .pro-card:nth-child(n + 7) {
      display: none;
    }

    &__button {
      display: block;
    }
  }
}

.feedback {
  color: var(--color-action-500);

  span {
    margin-left: var(--size-spacing-inline-100);
  }
}
