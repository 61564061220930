.orders__container {
  background-color: var(--color-neutral-light-200);
  height: 100vh;
}

.orders__title {
  background-color: var(--color-neutral-light-100);
  width: 100%;
}

.orders__content {
  display: flex;
  align-items: flex-start;
  justify-content: center;

  background-color: var(--color-neutral-light-200);

  > ul {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;

    > li:first-child {
      margin-top: var(--size-spacing-stack-500);
    }
  }
}
