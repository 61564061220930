.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--size-spacing-stack-1000);
  gap: var(--size-spacing-stack-100);
}

.description {
  text-align: center;
  line-height: var(--font-line-height-normal);
  color: var(--color-neutral-dark-300);
  font-size: var(--size-font-base);
  font-weight: var(--font-weight-regular);
}
