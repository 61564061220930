@import '~darrius/src/styles/mixins/media';

.changeNumber__form {
  width: 24rem;

  button {
    margin-top: 1rem;
  }

  @media (max-width: 768px) {
    width: 16rem;
  }
}
