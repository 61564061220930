@import '~darrius/src/styles/mixins/media';

.container {
  padding: 0 var(--size-spacing-stack-200);
  width: 100%;
}

.rate__header {
  padding: var(--size-spacing-stack-500) var(--size-spacing-inline-500) 0;

  &::after {
    display: block;
    margin: var(--size-spacing-stack-300) 0;
    background-color: var(--color-neutral-light-300);
    width: 100%;
    height: 1px;
    content: '';
  }

  > div {
    @include media(normal) {
      justify-content: center;
    }

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--size-spacing-stack-100);
  }
}

.rate__body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 0 var(--size-spacing-inline-500) var(--size-spacing-stack-500);

  a {
    @include media(normal) {
      width: 60%;
      text-align: center;
    }

    margin-top: var(--size-spacing-stack-300);

    width: 100%;
  }

  &_description {
    @include media(normal) {
      text-align: center;
    }

    line-height: var(--font-line-height-normal);
    color: var(--color-neutral-dark-200);
    font-size: var(--size-font-base);
    font-weight: var(--font-weight-regular);
  }
}
