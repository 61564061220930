/* stylelint-disable selector-max-compound-selectors */

@import '~darrius/src/styles/mixins/scale';
@import '~darrius/src/styles/mixins/media';
@import '~darrius/src/styles/mixins/display';

.main-section {
  .hero-section {
    &__title {
      @include bold-l;

      @include media(normal) {
        @include bold-xl;
      }
    }

    &__subtitle {
      @include regular-xs;

      @include media(normal) {
        @include regular-s;
      }
    }
  }

  .hero-section__image {
    display: flex;
  }

  :global {
    .services-categories {
      @include media(normal) {
        .subcategories {
          top: 6.8rem;
        }

        .card-header {
          display: var(--none, none);
        }

        .categories {
          padding-right: var(--size-spacing-inline-300);
          padding-left: var(--size-spacing-inline-300);

          > * {
            padding-top: var(--size-spacing-stack-100);
            padding-bottom: var(--size-spacing-stack-100);
          }
        }
      }

      margin-top: var(--size-spacing-stack-400);
    }
  }

  @media (max-width: 991px) {
    .hero-section {
      margin-top: var(--size-spacing-stack-500);
    }

    .hero-section__title {
      margin-bottom: var(--size-spacing-stack-300);
      line-height: var(--font-line-height-compact);
      font-size: var(--size-font-2xl);
    }

    .hero-section__subtitle {
      margin-bottom: var(--size-spacing-stack-600);
    }
  }

  @media (min-width: 992px) {
    .hero-section__title {
      margin-bottom: var(--size-spacing-stack-600);
    }
  }
}

.about-image {
  border-radius: 5px;
}

.download-buttons {
  margin-top: 48px;

  li {
    margin-right: 16px;
    margin-bottom: 16px;
  }
}

.link-group {
  @include media(normal) {
    columns: 2;
  }

  columns: 1;

  li {
    height: 50px;
  }
}

.our-values {
  @include media(normal) {
    align-self: flex-end;
    margin-right: 10%;
    border-radius: 0.5rem;
    width: 50%;
  }

  background-color: var(--brand-600);
  color: var(--shade-300);

  ul {
    margin-top: 48px;
  }

  li {
    margin-bottom: 16px;
  }

  @media (max-width: 992px) {
    img {
      width: 100%;
    }
  }
}

.register-as-pro {
  @include media(normal) {
    button {
      margin-top: var(--size-spacing-stack-700);
    }
  }

  background-color: var(--brand-600);
}

.banner-full {
  ul li {
    display: inline-block;
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
      margin-bottom: 0;
    }
  }
}

.profissional-button svg {
  position: relative;
  right: -30px;
  font-size: 23px;
}

.responsive-download-image {
  width: 491px;
  max-width: 100%;
  height: auto;
}

.statistics-statistic__title {
  margin-bottom: 0;

  p {
    color: var(--shade-100);
    font-size: var(--size-font-3xl);
    font-weight: var(--font-weight-semibold);
  }
}

.main-services_title,
.main-services_link {
  display: inline-block;
  margin: var(--size-border-width-heavy);
}

.hero-section__main-services {
  margin-top: var(--size-spacing-stack-400);
  margin-bottom: var(--size-spacing-stack-400);
}
