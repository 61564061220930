.chat_dialog__content {
  display: flex;

  flex-direction: column;
  align-items: flex-start;
  padding: var(--size-spacing-stack-900) var(--size-spacing-inline-700);

  max-width: 30rem;

  a {
    margin-top: var(--size-spacing-stack-300);
  }

  @media (max-width: 768px) {
    padding: var(--size-spacing-stack-900) var(--size-spacing-inline-300);

    a {
      margin-top: var(--size-spacing-stack-100);
    }

    a + a {
      margin-top: var(--size-spacing-stack-300);
    }
  }
}

.chat_dialog__title {
  margin-bottom: var(--size-spacing-stack-300);

  line-height: var(--font-line-height-compact);
  color: var(--color-neutral-dark-600);
  font-size: var(--size-font-xl);
  font-weight: var(--font-weight-semibold);
}

.chat_dialog__description {
  margin-bottom: var(--size-spacing-stack-300);

  line-height: var(--font-line-height-normal);
  color: var(--color-neutral-dark-400);
  font-size: var(--size-font-l);
  font-weight: var(--font-weight-regular);
}

.form__radio {
  display: flex;
  align-items: center;
  margin-bottom: var(--size-spacing-stack-fluid-500);
  list-style: none;
  gap: 1rem;

  input {
    flex-shrink: 0;
    cursor: pointer;
    width: 1.4rem;
    height: 1.4rem;
    accent-color: var(--color-action-500);
  }

  label {
    cursor: pointer;
    line-height: var(--font-line-height-compact);
    color: var(--color-neutral-dark-500);
    font-size: var(--size-font-l);
    font-weight: var(--font-weight-regular);
  }
}
