.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--size-spacing-stack-1000);
  gap: var(--size-spacing-stack-100);
}

.description {
  margin-bottom: var(--size-spacing-stack-500);

  text-align: center;
  font-weight: var(--font-weight-semibold);
}
