.blocked-order {
  &__back-button {
    all: unset;
    margin-bottom: var(--size-spacing-stack-300);
    cursor: pointer;
    width: fit-content;
    color: var(--color-neutral-dark-300);

    svg {
      width: var(--size-spacing-inline-400);
      height: var(--size-spacing-stack-400);
    }
  }

  &__title-content,
  &__text-content,
  &__subtitle-content,
  &__list-content {
    margin-bottom: var(--size-spacing-stack-500);
  }

  &__list-content {
    line-height: var(--font-line-height-normal);
    color: var(--color-neutral-dark-300);
    font-size: var(--size-font-m);
  }

  &__subtitle-content {
    font-weight: var(--font-weight-semibold);
  }

  &__list-content {
    margin-left: var(--size-spacing-inline-700);

    :global {
      li {
        list-style-type: disc;
      }
    }
  }

  &__text-content span {
    color: var(--color-action-500);
    font-weight: var(--font-weight-semibold);
  }

  &__buttons {
    :global {
      .button {
        margin-bottom: var(--size-spacing-stack-200);
      }

      .secondary {
        svg {
          width: var(--size-spacing-inline-500);
          height: var(--size-spacing-stack-500);
        }
      }
    }
  }
}
