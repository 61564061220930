.dialog__content {
  width: 400px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  gap: var(--size-spacing-stack-300);

  h1 {
    color: var(--color-neutral-dark-600);
  }

  p {
    text-align: left;
    line-height: var(--font-line-height-normal);
    color: var(--color-neutral-dark-400);
    font-size: var(--size-font-base);
    font-weight: var(--font-weight-regular);
  }
}

.user_image {
  margin: 0;
}

.image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  &__name {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    width: 100%;

    gap: var(--size-spacing-stack-100);

    > div {
      display: flex;
      align-items: center;
    }

    span {
      line-height: var(--font-line-height-strict);
      font-size: var(--size-font-m);
      font-weight: var(--font-weight-semibold);
    }
  }

  &__ballon {
    margin: var(--size-spacing-stack-300) 0;

    border-top-left-radius: calc(var(--size-border-radius-l) + 8px);
    border-top-right-radius: calc(var(--size-border-radius-l) + 6px);
    border-bottom-left-radius: calc(var(--size-border-radius-l) + 8px);
    border-bottom-right-radius: var(--size-border-radius-s);

    background-color: var(--color-action-200);

    padding: var(--size-spacing-stack-200);

    span {
      text-align: left;
      line-height: var(--font-line-height-compact);
      font-size: var(--size-font-base);
      font-weight: var(--font-weight-semibold);
    }
  }
}
