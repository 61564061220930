@import '~darrius/src/styles/mixins/media';

// Estilo da busca
.search-category {
  position: relative;

  &__search {
    display: flex;
    justify-content: space-between;
    border: var(--size-border-width-thick) solid var(--color-neutral-light-500);
    border-radius: var(--size-border-radius-m);
    padding: var(--size-spacing-inline-300) var(--size-spacing-stack-100)
      var(--size-spacing-inline-300) var(--size-spacing-inline-300);

    &:hover {
      border: var(--size-border-width-thick) solid var(--action-500);
    }

    &__clean {
      border: none;
      background-color: var(--color-neutral-light-100);
      cursor: pointer;

      &_isActived {
        svg {
          fill: var(--color-action-500);
        }
      }
    }

    svg {
      fill: var(--color-action-300);
      width: 24px;
      height: 24px;
    }
  }

  label {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__label {
    svg {
      fill: var(--color-action-500);
      width: 35px;
      height: 35px;
    }
  }

  &__clean-button {
    all: unset;
  }

  input {
    margin-right: var(--size-spacing-inline-400);
    margin-left: var(--size-spacing-inline-300);
    border: initial;
    background-color: var(--shade-900);
    padding: 0;
    width: 75%;
    font-size: var(--size-font-m);

    &::placeholder {
      color: var(--shade-500);
      font-size: var(--size-font-base);
    }
  }

  //BOX das categorias
  &__dropdown {
    @include media(normal) {
      max-height: 65vh; // TODO: Melhorar esse número mágico.
    }

    display: flex;
    position: absolute;
    z-index: 10000;
    border: var(--size-border-width-thick) solid var(--color-neutral-light-500);
    border-top: none;
    border-radius: var(--size-border-radius-m);
    background-color: var(--color-neutral-light-100);
    padding: var(--size-spacing-inline-100) 0 var(--size-spacing-inline-100) 0;
    width: 100%;
    max-height: 50vh;
    overflow: scroll;

    ul {
      width: 100%;
      list-style: none;
      line-height: var(--font-line-height-normal);
      font-size: var(--size-font-m);

      a {
        text-decoration: none;
        color: var(--color-neutral-dark-600);
      }

      li {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: var(--size-spacing-inline-300);

        &:hover {
          background-color: var(--color-neutral-light-200);
        }
      }
    }

    &-loading {
      div {
        margin-left: 10px;
        width: 2rem;
        height: 2rem;
      }
    }
  }

  ::-webkit-scrollbar-track {
    background: var(--shade-700);
  }

  ::-webkit-scrollbar {
    background: var(--shade-700);
    width: 6px;
    height: 0;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 30px;
    background: var(--shade-500);
  }
}

.search__clean_isActived {
  svg {
    fill: var(--color-action-500);
  }
}
