.pros-not-found {
  margin-top: var(--size-spacing-stack-500);

  &__pros-not-found {
    align-content: center;
  }

  :global {
    .paragraph {
      margin-bottom: var(--size-spacing-stack-100);
    }
  }

  &__button {
    margin-top: var(--size-spacing-stack-200);
  }

  &__img-not-found {
    margin-bottom: var(--size-spacing-stack-200);

    @media (max-width: 768px) {
      img {
        max-width: 75px;
        height: auto;
      }
    }
  }

  &__image-qrcode {
    :global {
      .paragraph-1 p {
        color: var(--color-action-500);
      }
    }
  }

  &__qrcode-section {
    max-width: 300px;
  }
}
