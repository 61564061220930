@import '~darrius/src/styles/mixins/media';
@import '~darrius/src/styles/mixins/scale';

.pro-avatar {
  @include media(normal) {
    width: 136px;
    height: 136px;
  }

  width: 72px;
  height: 72px;
}
