@import '~darrius/src/styles/mixins/media';

.signin__details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;

  gap: var(--size-spacing-stack-300);
}

.signin__title {
  text-align: center;
  line-height: var(--font-line-height-narrow);
  color: var(--color-neutral-dark-600);
  font-size: var(--size-font-xl);
  font-weight: var(--font-weight-semibold);
}

.signin__subtitle {
  @include media(false, medium) {
    font-size: var(--size-font-base);
  }

  text-align: center;
  line-height: var(--font-line-height-strict);
  color: var(--color-neutral-dark-200);
  font-size: var(--size-font-m);
  font-weight: var(--font-weight-regular);
}

.signin__content_input {
  display: flex;
  flex-direction: column;

  margin-top: var(--size-spacing-stack-500);

  width: 100%;

  gap: var(--size-spacing-stack-300);
}

.signin__pro {
  text-align: center;
  line-height: var(--font-line-height-compact);
  color: var(--color-neutral-dark-200);
  font-size: var(--size-font-base);
  font-weight: var(--font-weight-regular);
}

.signin__pro_link {
  @include media(false, medium) {
    font-size: var(--size-font-base);
  }

  text-align: center;
  line-height: var(--font-line-height-strict);
  color: var(--color-action-400);
  font-size: var(--size-font-base);
  font-weight: var(--font-weight-semibold);

  &:hover {
    text-decoration: underline;
  }
}
