.list {
  list-style-type: var(--none, none);
}

.list__item {
  margin-bottom: 16px;
}

.card-container {
  margin-top: 0;
}

.pro-card {
  &:hover {
    background: var(--action-900);
  }
}

.pro-card--selected {
  background: var(--action-900);
}

.pro-avatar {
  width: 60px;
  height: 60px;
}

.list__button {
  border: 0;
  background: transparent;
  cursor: pointer;

  min-width: 100%;
}
