.selector {
  display: flex;
  flex-direction: column;
  gap: var(--size-spacing-stack-300);

  &__active {
    transition: background-color 0.2s ease;
    background-color: var(--color-action-600);

    span {
      color: var(--color-neutral-light-100);
    }
  }

  label {
    display: flex;
    align-items: center;
    border-radius: var(--size-border-radius-m);
    box-shadow: 0 4px 8px 3px rgba(10, 33, 94, 0.07);
    cursor: pointer;
    padding: var(--size-spacing-stack-200) var(--size-spacing-inline-300);
    height: 72px;

    &:hover:not(.selector__active) {
      background-color: var(--color-neutral-light-200);
      color: var(--color-neutral-dark-600);
    }

    span {
      text-align: left;
      line-height: var(--font-line-height-compact);
      font-size: var(--size-font-m);
      font-weight: var(--font-weight-regular);
    }

    input[type='radio'] {
      display: none;
    }
  }
}
